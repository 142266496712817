import React, { Suspense, lazy, Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from './components/section-components/ScrollToTop';
import Privacydata from './data/privacydata.json';
import Termdata from './data/termdata.json';
import Refunddata from './data/refunddata.json';
import Singleblogdata from './data/single-blogdata.json';
import Blogdata from './data/blogdate.json';

// Lazy load components
const Home = lazy(() => import('./components/home'));
const Faq = lazy(() => import('./components/faq'));
const About = lazy(() => import('./components/about'));
const Privacy = lazy(() => import('./components/privacy'));
const Refund = lazy(() => import('./components/refund'));
const Term = lazy(() => import('./components/term'));
const Contact = lazy(() => import('./components/contact'));
const Login = lazy(() => import('./components/login'));
const Register = lazy(() => import('./components/register'));
const StudentLifeCycle = lazy(() => import('./components/studentLifeCycle'));
const InstitutesLifeCycle = lazy(() => import('./components/institutesLifeCycle'));
const Blog = lazy(() => import('./components/blog'));
const BlogDetails = lazy(() => import('./components/blog-details'));
const ForgetPassword = lazy(() => import('./components/forgetPassword'));
// const LearnEarn = lazy(() => import('./components/learnEarn'));

// PTE AC
const PteAcademic = lazy(() => import('./components/pte-academic'));
const TestFormatPteAc = lazy(() => import('./components/pte-ac-test-format'));
const TestFormatPteAcSpW = lazy(() => import('./components/pte-ac-about-part-writing-speaking'));
const TestFormatPteAcRead = lazy(() => import('./components/pte-ac-about-part-reading'));
const TestFormatPteAcListening = lazy(() => import('./components/pte-ac-about-part-listening'));
const PreparationPteAc = lazy(() => import('./components/preparation-pte-ac'));
const PreparationPteAcNav = lazy(() => import('./components/pricing-nav/preparation-nav-pte-ac'));
const ScoringPteAc = lazy(() => import('./components/scoring-pte-ac'));

// PTE CORE
const PteCore = lazy(() => import('./components/pte-core'));
const TestFormatPteCore = lazy(() => import('./components/pte-core-test-format'));
const TestFormatPteCoreSW = lazy(() => import('./components/pte-core-about-part-writing-speakin'));
const TestFormatPteCoreRead = lazy(() => import('./components/pte-core-about-part-reading'));
const TestFormatPteCoreListening = lazy(() => import('./components/pte-core-about-part-listening'));
const PreparationPteCore = lazy(() => import('./components/preparation-pte-core'));
const PreparationPteCoreNav = lazy(() => import('./components/pricing-nav/preparation-nav-pte-core'));
const ScoringPteCore = lazy(() => import('./components/scoring-pte-core'));

// TOEFL
const Toefl = lazy(() => import('./components/toefl'));
const PreparationToefl = lazy(() => import('./components/preparation-toefl'));
const TestFormatToefl = lazy(() => import('./components/toefl-test-format'));
const TestFormatToeflReading = lazy(() => import('./components/toefl-about-part-reading'));
const TestFormatToeflListening = lazy(() => import('./components/toefl-about-part-listening'));
const TestFormatToeflSpeaking = lazy(() => import('./components/toefl-about-part-speaking'));
const TestFormatToeflWriting = lazy(() => import('./components/toefl-about-part-writing'));
const PreparationToeflNav = lazy(() => import('./components/pricing-nav/preparation-nav-toefl'));
const ScoringToefl = lazy(() => import('./components/scoring-toefl'));

// CELPIP
const Celpip = lazy(() => import('./components/celpip'));
const TestFormatCelpip = lazy(() => import('./components/celpip-test-format'));
const TestFormatCelpipReading = lazy(() => import('./components/celpip-about-part-reading'));
const TestFormatCelpipListening = lazy(() => import('./components/celpip-about-part-listening'));
const TestFormatCelpipSpeaking = lazy(() => import('./components/celpip-about-part-speaking'));
const TestFormatCelpipWriting = lazy(() => import('./components/celpip-about-part-writing'));
const PreparationCelpip = lazy(() => import('./components/preparation-celpip'));
const TestFormatCelpipLsListening = lazy(() => import('./components/celpip-ls-about-part-listening'));
const TestFormatCelpipLsSpeaking = lazy(() => import('./components/celpip-ls-about-part-speaking'));
const PreparationCelpipNav = lazy(() => import('./components/pricing-nav/preparation-nav-celpip'));
const ScoringCelpip = lazy(() => import('./components/scoring-celpip'));

// CAEL
const Cael = lazy(() => import('./components/cael'));
const TestFormatCael = lazy(() => import('./components/cael-test-format'));
const TestFormatCaelSpeaking = lazy(() => import('./components/cael-about-part-speaking'));
const TestFormatCaelReading = lazy(() => import('./components/cael-about-part-reading'));
const TestFormatCaelListening = lazy(() => import('./components/cael-about-part-listening'));
const TestFormatCaelAcademicA = lazy(() => import('./components/cael-about-part-academic-a'));
const TestFormatCaelAcademicB = lazy(() => import('./components/cael-about-part-academic-b'));
const PreparationCaelNav = lazy(() => import('./components/pricing-nav/preparation-nav-cael'));
const PreparationCael = lazy(() => import('./components/preparation-cael'));
const ScoringCael = lazy(() => import('./components/scoring-cael'));

// CD IELTS AC
const CdIeltsAcademic = lazy(() => import('./components/cd-ielts-academic'));
const PreparationCdIeltsAc = lazy(() => import('./components/preparation-cd-ielts-ac'));
const PreparationCdIeltsAcNav = lazy(() => import('./components/pricing-nav/preparation-nav-cd-ielts-ac'));
const TestFormatCdIeltsAc = lazy(() => import('./components/cd-ielts-ac-test-format'));
const TestFormatCdIeltsAcReading = lazy(() => import('./components/cd-ielts-ac-about-part-reading'));
const TestFormatCdIeltsAcListening = lazy(() => import('./components/cd-ielts-ac-about-part-listening'));
const TestFormatCdIeltsAcWriting = lazy(() => import('./components/cd-ielts-ac-about-part-writing'));
const TestFormatCdIeltsAcSpeaking = lazy(() => import('./components/cd-ielts-ac-about-part-speaking'));
const ScoringCdieltsAc = lazy(() => import('./components/scoring-cd-ielts-ac'));

// CD IELTS GT
const CdIeltsGeneralTraining = lazy(() => import('./components/cd-ielts-general-training'));
const TestFormatCdIeltsGt = lazy(() => import('./components/cd-ielts-gt-test-format'));
const TestFormatCdIeltsGtListening = lazy(() => import('./components/cd-ielts-gt-about-part-listening'));
const TestFormatCdIeltsGtReading = lazy(() => import('./components/cd-ielts-gt-about-part-reading'));
const TestFormatCdIeltsGtWriting = lazy(() => import('./components/cd-ielts-gt-about-part-writing'));
const TestFormatCdIeltsGtSpeaking = lazy(() => import('./components/cd-ielts-gt-about-part-speaking'));
const PreparationCdIeltsGt = lazy(() => import('./components/preparation-cd-ielts-gt'));
const PreparationCdIeltsGtNav = lazy(() => import('./components/pricing-nav/preparation-nav-cd-ielts-gt'));
const ScoringCdieltsGt = lazy(() => import('./components/scoring-cd-ielts-gt'));

class Root extends Component {
    render() {
        return (
            <Router>
                <div>
                    <ScrollToTop />
                    <Suspense fallback={<div></div>}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/about" component={About} />
                            <Route path="/privacypolicy" render={() => <Privacy data={Privacydata} />} />
                            <Route path="/terms" render={() => <Term data={Termdata} />} />
                            <Route path="/refundpolicy" render={() => <Refund data={Refunddata} />} />
                            <Route path="/blog" render={() => <Blog data={Blogdata} />} />
                            <Route path="/blog-details" render={() => <BlogDetails data={Singleblogdata} />} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/sign-in" component={Login} />
                            <Route path="/register" component={Register} />
                            <Route path="/faq" component={Faq} />
                            <Route path="/studentlifecycle" component={StudentLifeCycle} />
                            <Route path="/instituteslifecycle" component={InstitutesLifeCycle} />
                            <Route path="/forgot-password" component={ForgetPassword} />
                            {/* <Route path="/learn-earn" component={LearnEarn} /> */}

                            {/* CD IELTS GT */}
                            <Route path="/cd-ielts-gt" component={CdIeltsGeneralTraining} />
                            <Route path="/cd-ielts-gt-test-format" component={TestFormatCdIeltsGt} />
                            <Route path="/test-format-cd-ielts-gt-listening" component={TestFormatCdIeltsGtListening} />
                            <Route path="/test-format-cd-ielts-gt-reading" component={TestFormatCdIeltsGtReading} />
                            <Route path="/test-format-cd-ielts-gt-writing" component={TestFormatCdIeltsGtWriting} />
                            <Route path="/test-format-cd-ielts-gt-speaking" component={TestFormatCdIeltsGtSpeaking} />
                            <Route path="/preparation-cd-ielts-gt" component={PreparationCdIeltsGt} />
                            <Route path="/preparation-cd-ielts-gt-pricing" component={PreparationCdIeltsGtNav} />
                            <Route path="/scoring-cd-ielts-gt" component={ScoringCdieltsGt} />

                            {/* CD IELTS AC */}
                            <Route path="/cd-ielts-academic" component={CdIeltsAcademic} />
                            <Route path="/preparation-cd-ielts-ac" component={PreparationCdIeltsAc} />
                            <Route path="/preparation-cd-ielts-ac-pricing" component={PreparationCdIeltsAcNav} />
                            <Route path="/cd-ielts-ac-test-format" component={TestFormatCdIeltsAc} />
                            <Route path="/test-format-cd-ielts-ac-reading" component={TestFormatCdIeltsAcReading} />
                            <Route path="/test-format-cd-ielts-ac-listening" component={TestFormatCdIeltsAcListening} />
                            <Route path="/test-format-cd-ielts-ac-writing" component={TestFormatCdIeltsAcWriting} />
                            <Route path="/test-format-cd-ielts-ac-speaking" component={TestFormatCdIeltsAcSpeaking} />
                            <Route path="/scoring-cd-ielts-ac" component={ScoringCdieltsAc} />

                            {/* PTE CORE */}
                            <Route path="/pte-core" component={PteCore} />
                            <Route path="/pte-core-test-format" component={TestFormatPteCore} />
                            <Route path="/test-format-pte-core-sw" component={TestFormatPteCoreSW} />
                            <Route path="/test-format-pte-core-read" component={TestFormatPteCoreRead} />
                            <Route path="/test-format-pte-core-listening" component={TestFormatPteCoreListening} />
                            <Route path="/preparation-pte-core" component={PreparationPteCore} />
                            <Route path="/preparation-pte-core-pricing" component={PreparationPteCoreNav} />
                            <Route path="/scoring-pte-core" component={ScoringPteCore} />

                            {/* PTE ACADEMIC */}
                            <Route path="/pte-academic" component={PteAcademic} />
                            <Route path="/pte-ac-test-format" component={TestFormatPteAc} />
                            <Route path="/test-format-pte-ac-sp-w" component={TestFormatPteAcSpW} />
                            <Route path="/test-format-pte-ac-reading" component={TestFormatPteAcRead} />
                            <Route path="/test-format-pte-ac-listening" component={TestFormatPteAcListening} />
                            <Route path="/preparation-pte-ac" component={PreparationPteAc} />
                            <Route path="/preparation-pte-ac-pricing" component={PreparationPteAcNav} />
                            <Route path="/scoring-pte-ac" component={ScoringPteAc} />

                            {/* CELPIP */}
                            <Route path="/celpip" component={Celpip} />
                            <Route path="/celpip-test-format" component={TestFormatCelpip} />
                            <Route path="/test-format-celpip-reading" component={TestFormatCelpipReading} />
                            <Route path="/test-format-celpip-listening" component={TestFormatCelpipListening} />
                            <Route path="/test-format-celpip-speaking" component={TestFormatCelpipSpeaking} />
                            <Route path="/test-format-celpip-writing" component={TestFormatCelpipWriting} />
                            <Route path="/preparation-celpip" component={PreparationCelpip} />
                            <Route path="/test-format-celpip-ls-listening" component={TestFormatCelpipLsListening} />
                            <Route path="/test-format-celpip-ls-speaking" component={TestFormatCelpipLsSpeaking} />
                            <Route path="/preparation-celpip-pricing" component={PreparationCelpipNav} />
                            <Route path="/scoring-celpip" component={ScoringCelpip} />

                            {/* CAEL */}
                            <Route path="/cael" component={Cael} />
                            <Route path="/cael-test-format" component={TestFormatCael} />
                            <Route path="/test-format-cael-speaking" component={TestFormatCaelSpeaking} />
                            <Route path="/test-format-cael-reading" component={TestFormatCaelReading} />
                            <Route path="/test-format-cael-listening" component={TestFormatCaelListening} />
                            <Route path="/test-format-cael-academic-a" component={TestFormatCaelAcademicA} />
                            <Route path="/test-format-cael-academic-b" component={TestFormatCaelAcademicB} />
                            <Route path="/preparation-cael-pricing" component={PreparationCaelNav} />
                            <Route path="/preparation-cael" component={PreparationCael} />
                            <Route path="/scoring-cael" component={ScoringCael} />

                            {/* TOEFL */}
                            <Route path="/toefl" component={Toefl} />
                            <Route path="/preparation-toefl" component={PreparationToefl} />
                            <Route path="/toefl-test-format" component={TestFormatToefl} />
                            <Route path="/toefl-about-part-reading" component={TestFormatToeflReading} />
                            <Route path="/toefl-about-part-listening" component={TestFormatToeflListening} />
                            <Route path="/toefl-about-part-speaking" component={TestFormatToeflSpeaking} />
                            <Route path="/toefl-about-part-writing" component={TestFormatToeflWriting} />
                            <Route path="/preparation-toefl-pricing" component={PreparationToeflNav} />
                            <Route path="/scoring-toefl" component={ScoringToefl} />
                        </Switch>
                    </Suspense>
                </div>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('riyaqas'));

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// ScrollToTop component
const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // Scroll to top on route change
    });
    return () => {
      unlisten(); // Cleanup function to remove listener when component unmounts
    };
  }, [history]);

  return null; // ScrollToTop component doesn't render anything
};

export default withRouter(ScrollToTop); // Wrap ScrollToTop with withRouter HOC to access history prop
